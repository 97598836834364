<template>
  <div>
    <v-menu
      ref="datePickerMenu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="localValue"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          autocomplete="off"
          :disabled="disabled"
          v-on="on"
          :placeholder="placeholder"
          :id="id"
          :label="label"
          v-bind="attrs"
          v-model="dateRangeText"
          class="datepicker-input"
          append-icon="mdi-calendar-outline"
          hide-details="auto"
          readonly
          dense
          outlined
          clearable
        >
        </v-text-field>
      </template>
      <v-date-picker
        ref="datePicker"
        v-model="dates"
        locale="pt-br"
        no-title
        scrollable
        range
        :type="type"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" class="btn-ag-secondary" @click="onClose()"> Cancelar </v-btn>
        <v-btn text class="btn-ag-primary" @click="onConfirm(localValue)"> Confirmar </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'AGDatePickerRangeMonth',

  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'month',
    },
    placeholder: {
      type: String,
      required: false
    },
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    requireTwoDates: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      menu: false,
      localValue: this.value,
      oldValue: []
    };
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    }
  },
  computed: {
    dateRangeText: {
      get: function () {
        let datesComputed = [];
        if (this.localValue.length > 0) {
          this.localValue.forEach((val, index) => {
            datesComputed[index] = this.formatDate(val);
          });
        }
        return datesComputed.sort().join(' - ');
      },
      set: function (value) {
        if ([null, undefined].includes(value)) {
          value = [];
        }
        this.$emit('input', value);
      }
    },
    dates: {
      get: function () {
        return this.localValue.sort();
      },
      set: function (value) {
        this.localValue = value;
        this.$emit('change', value);
        this.$emit('input', value);
      },
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month] = date.split('-');
      return `${month}/${year}`;
    },
    onClose(){
      this.dates = this.oldValue;
      this.$refs.datePickerMenu.save(this.dates);
      this.menu = false;
    },
    onConfirm(value) {
      this.setSecondDateBasedOnFirstChoice(value);
      this.dates =   this.value;
      this.oldValue = this.dates;
      this.$refs.datePickerMenu.save(this.dates);
    },
    setSecondDateBasedOnFirstChoice(val) {
      if(this.requireTwoDates && [undefined, null].includes(this.dates[1])) {
        this.dates[1] = val[0];
        this.$refs.datePicker.emitInput(val[0]);
        this.$emit('input', this.dates);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import './src/design/components/datepicker.scss';
</style>
