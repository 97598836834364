var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          ref: "datePickerMenu",
          attrs: {
            "close-on-content-click": false,
            "return-value": _vm.localValue,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "auto",
          },
          on: {
            "update:returnValue": function ($event) {
              _vm.localValue = $event
            },
            "update:return-value": function ($event) {
              _vm.localValue = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "datepicker-input",
                          attrs: {
                            autocomplete: "off",
                            disabled: _vm.disabled,
                            placeholder: _vm.placeholder,
                            id: _vm.id,
                            label: _vm.label,
                            "append-icon": "mdi-calendar-outline",
                            "hide-details": "auto",
                            readonly: "",
                            dense: "",
                            outlined: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.dateRangeText,
                            callback: function ($$v) {
                              _vm.dateRangeText = $$v
                            },
                            expression: "dateRangeText",
                          },
                        },
                        "v-text-field",
                        attrs,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _c(
            "v-date-picker",
            {
              ref: "datePicker",
              attrs: {
                locale: "pt-br",
                "no-title": "",
                scrollable: "",
                range: "",
                type: _vm.type,
              },
              model: {
                value: _vm.dates,
                callback: function ($$v) {
                  _vm.dates = $$v
                },
                expression: "dates",
              },
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "btn-ag-secondary",
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onClose()
                    },
                  },
                },
                [_vm._v(" Cancelar ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn-ag-primary",
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onConfirm(_vm.localValue)
                    },
                  },
                },
                [_vm._v(" Confirmar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }